import { BenefitsDto, CostsAndBenefitsValueItemBenefitDto } from '@/service-proxies/service-proxies.g';
import { CostsAndBenefitsValueItem } from './costs-and-benefits-value-item';
import { CostsAndBenefitsValueItemBenefit } from './costs-and-benefits-value-item-benefit';

export class Benefits {
    indirectBenefits?: CostsAndBenefitsValueItemBenefit[];
    revenue: CostsAndBenefitsValueItem;
    opex: CostsAndBenefitsValueItem;
    capex: CostsAndBenefitsValueItem;
    itBenefits: CostsAndBenefitsValueItem;


    public constructor(data?: BenefitsDto) {
        this.revenue = new CostsAndBenefitsValueItem(data?.revenue);
        this.opex = new CostsAndBenefitsValueItem(data?.opex);
        this.capex = new CostsAndBenefitsValueItem(data?.capex);
        this.itBenefits = new CostsAndBenefitsValueItem(data?.itBenefits);
        if(!data?.indirectBenefits?.length) {
            this.indirectBenefits= [(new CostsAndBenefitsValueItemBenefit())]
        } else {
            this.indirectBenefits = data?.indirectBenefits && data?.indirectBenefits?.map(
                (benefit: CostsAndBenefitsValueItemBenefitDto) =>
                    new CostsAndBenefitsValueItemBenefit(benefit)
            );
        }
    }
}
