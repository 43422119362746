const PermissionNames = {
    ApproveIdea: 'ApproveIdea',
    DeleteIdea: 'DeleteIdea',
    EditIdea: 'EditIdea',
    UpdateStatus: 'UpdateStatus',
    FinancialView: 'FinancialView',
    DeleteComment: 'DeleteComment',
    CostAndBenefitsView: 'CostAndBenefitsView',
    CostAndBenefitsWrite: 'CostAndBenefitsWrite',
    Admin: 'Admin',
    Connector: 'Connector',
}
export default PermissionNames;
