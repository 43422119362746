import { CostsAndBenefitsValueItemRunCostDto, RealizedAndScaledBenefitsGetDto } from '@/service-proxies/service-proxies.g';
import { CostsAndBenefitsValueItem } from '../costs-and-benefits/costs-and-benefits-value-item';
import { CostsAndBenefitsValueItemRunCost } from '../costs-and-benefits/costs-and-benefits-value-item-run-cost';
import { RealizedAndScaledBenefitsBase } from './realized-and-scaled-benefits-base';

export class RealizedAndScaledBenefits {
    realized?: RealizedAndScaledBenefitsBase;
    scaled?: RealizedAndScaledBenefitsBase;
    newChangeCosts?: CostsAndBenefitsValueItem;
    newRunCosts?: CostsAndBenefitsValueItemRunCost[];

    public constructor(data?: RealizedAndScaledBenefitsGetDto) {
        this.realized = new RealizedAndScaledBenefitsBase(data?.realized);
        this.scaled = new RealizedAndScaledBenefitsBase(data?.scaled);
        this.newChangeCosts = new CostsAndBenefitsValueItem(data?.newChangeCosts);
        this.newRunCosts = data?.newRunCosts?.length ? data?.newRunCosts?.map((cost: CostsAndBenefitsValueItemRunCostDto) =>
            new CostsAndBenefitsValueItemRunCost(cost)) : [new CostsAndBenefitsValueItemRunCost()];
    }
}
