



























































import { Component, Watch, Vue } from 'vue-property-decorator';

const PAGES: {[key: string]: string} = {
    HOME: 'Home',
    PROJECTS: 'Projects',
    FINANCIAL: 'Financial',
    FINANCIAL_EVALUATION: 'FinancialEvaluation',
    IDEA_EDIT: 'IdeaEdit',
    CLOSURE_REPORT: 'ClosureReport'
};

const showToolbarOn = [
    PAGES.HOME,
    PAGES.PROJECTS,
    PAGES.FINANCIAL
];

type Breadcrumb = {
    label: any;
    href: string;
    isActive?: boolean | undefined;
};

@Component({
    name: 'header-toolbar',
})

export default class HeaderToolbar extends Vue {
    private showToolbar = false;
    private showToolbarRight = true;
    private breadcrumb: Breadcrumb[] = [];

    @Watch('$store.state.isLoading')
    onLoadingStateChanged(): void {
        this.breadcrumb = this.getIdeaOverviewCrumbs();
    }

    @Watch('$route.name')
    onRouteChanged(name: string | null | undefined): void {
        this.showToolbar = showToolbarOn.some(route => route === name);
        this.showToolbarRight = (name !== PAGES.FINANCIAL);

        if (name === PAGES.FINANCIAL_EVALUATION) {
            const {usecaseName} = this.$route.query;
            this.breadcrumb = this.getCostsOverviewCrumbs(usecaseName);
        }
    }

    @Watch('$route.path')
    onRoutePath(): void {
        this.breadcrumb = this.getIdeaOverviewCrumbs();
    }

    private mounted(): void {
        this.onRouteChanged(this.$route.name);
    }

    private getIdeaOverviewCrumbs(): Breadcrumb[] {
        const breadcrumbs = [
            {
                label: this.$t('projectIdeasOverview'),
                href: PAGES.HOME,
            },
            {
                label: this.$store.state.isLoading ? '...' : this.$store.state.ideas.idea &&
                    this.$store.state.ideas.idea.title ||
                    this.$route.name === 'Submit' && this.$t('submitIdea') || '',
                isActive: true,
                href: PAGES.IDEA_EDIT,
            },
        ];
        if(this.$route.name === 'ClosureReport')
            breadcrumbs.push({
                label: this.$t('closureReport'),
                href: PAGES.CLOSURE_REPORT,
            });
        return breadcrumbs;
    }

    private getCostsOverviewCrumbs(usecaseName: any): Breadcrumb[] {
        return [
            {
                label: this.$t('costsOverview') as string,
                href: '/financial?view=costs'
            },
            {
                label: usecaseName || '',
                isActive: true,
                href: '#'
            }
        ]
    }

    private created(): void {
        this.onRouteChanged(this.$route.path);
    }

    private get user(): any {
        return this.$store.state.user.user
    }

    private get myProjects(): boolean {
        return this.$route.params.area === 'my'
    }

    private navigate(event: any): void {
        const {href} = event;
        if(href) {
            if(href === PAGES.IDEA_EDIT || href === PAGES.CLOSURE_REPORT) {
                this.$router.push({ name: href, params: {id: this.$store.state.ideas.idea.id} });
                return;
            }
            this.$router.push({ name: href });
        }
    }

    private goto(area: string, view?: string): void {

        if(this.$route.name == 'Home'){
            this.$router.push({
                params: {
                    area,
                },
                query: {
                    view,
                },
            })
        } else if(this.$route.name == 'Financial') {
            this.$router.push({
                params: {
                    area,
                },
                query: {
                    view:this.$route.query.view,
                },
            })
        }

    }

    private gotoOverview(): void {
        this.$router.push('/')
    }

    private gotoSubmit(): void {
        this.$router.push({ name: 'Submit' })
    }
}
