import { CommentDto } from '@/service-proxies/service-proxies.g';

export class Comment {
    id?: number;
    createdBy?: string;
    createdAt?: Date;
    updatedAt?: Date;
    updatedBy?: string;
    text?: string;
    createdByFullName?: string;
    createdByEmail?: string;

    public constructor(data?: CommentDto) {
        this.id = data?.id;
        this.text = data?.text;
    }
}
