export class UserProfile {
    public name?: string;
    public email?: string;
    public userKid?: string;
    public department?: string;

    public constructor(data?: any) {
        this.name = data?.name;
        this.email = data?.email;
        this.userKid = data?.kid;
        this.department = data?.department;
    }
}
