import store from '@/store';
import PermissionNames from '@/utils/permission-names';
import { actions, getters } from '@/store/types';
import { Status, IIdeaDto } from '@/service-proxies/service-proxies.g';
export default class Permissions {

    public static canViewFinancial(): boolean {
        return this.hasFinancialViewPermission();
    }

    public static async canViewFinancialAsync(): Promise<boolean> {
        await this.loadPermissionsIfNotLoaded();
        return this.canViewFinancial();
    }

    public static async canViewClosureReportAsync(ideaId: string): Promise<boolean> {
        if(!Object.keys(this.getCurrentIdea()).length) {
            await store.dispatch(actions.LOAD_IDEA, ideaId);
        }
        const idea = this.getCurrentIdea();
        if(idea.id && idea.statusId === Status.InUse) {
            if(this.isIdeaBusinessOwner()) {
                return idea.achievementOfUseCase !== 0 &&
                idea.achievementOfUseCase !== undefined &&
                idea.achievementOfUseCase !== null;
            }
            else {
                if(this.isIdeaProductOwner()) {
                    return true;
                }
            }
        }
        return false;
    }

    public static canDeleteIdea(idea: IIdeaDto): boolean {
        if (this.isAdmin()
            || (this.isCoodeConnectorWithDeleteIdeaPermission()
                && this.userBelongsToIdeaDepartment(idea?.businessDepartment)
                && this.ideaStatusIsLowerThanPoc(idea?.statusId))
            || (this.isIdeaInitiator(idea.createdBy)
                && this.ideaStatusIsSubmitted(idea?.statusId) )
            || this.ideaStatusIsDraft(idea?.statusId)) {
            return true;
        }
        return false;
    }

    public static canUpdateRelatedUseCase(): boolean {
        return this.canUpdateIdeaStatus()
            || this.isIdeaBusinessOwner()
            || this.isIdeaProductOwner()
            || this.isIdeaInitiator(this.getCurrentIdea()?.createdBy);
    }

    public static canUpdateIdeaStatus(): boolean {
        return this.isAdmin()
            || (this.isCoodeConnector() && this.hasUpdateStatusPermission())
            || this.isIdeaProjectManager()
    }

    public static canEditCostsAndBenefits(ideaStatus: number): boolean {
        return this.isAdmin() ||
            (this.isIdeaProductOwner() || this.isIdeaProjectManager()) &&
                this.ideaStatusIsLowerThanDeliveryApproved(ideaStatus);
    }

    public static isProductOwnerOrProjectManager(): boolean {
        return this.isIdeaProductOwner() || this.isIdeaProjectManager();
    }

    public static canEditScaledBenefits(): boolean {
        return (this.isAdmin() || this.isIdeaProjectManager() || this.isIdeaProductOwner());
    }

    public static canViewCostsAndBenefits(): boolean {
        if (this.isAdmin()
            || this.isIdeaProductOwner()
            || this.isIdeaBusinessOwner()
            || this.isIdeaProjectManager()) {
            return true;
        }

        if (this.isCoodeConnector()) {
            return (this.hasViewCostsAndBenefitsPermission()
                || this.hasEditCostsAndBenefitsPermission())
        }

        return this.hasViewCostsAndBenefitsPermission()
            || this.hasEditCostsAndBenefitsPermission();
    }

    public static canViewIdeaCostsAndBenefitsSummary(): boolean {
        if (this.isAdmin()
            || this.isIdeaInitiator(this.getCurrentIdea()?.createdBy)
            || this.isIdeaProductOwner()
            || this.isIdeaBusinessOwner()
            || this.isIdeaProjectManager()) {
            return true;
        }

        if (this.isCoodeConnector()) {
            return (this.hasViewCostsAndBenefitsPermission()
                || this.hasEditCostsAndBenefitsPermission())
        }

        return this.hasViewCostsAndBenefitsPermission()
            || this.hasEditCostsAndBenefitsPermission();
    }

    public static isAdmin(): boolean {
        const userPermissions = this.getUserPermissions();
        return userPermissions
            ? userPermissions.includes(PermissionNames.Admin)
            : false;
    }

    private static hasFinancialViewPermission(): boolean {
        const userPermissions = this.getUserPermissions();
        return userPermissions
            ? userPermissions.includes(PermissionNames.FinancialView)
            : false;
    }

    public static hasUpdateStatusPermission(): boolean {
        const userPermissions = this.getUserPermissions();
        return userPermissions
            ?   userPermissions.includes(PermissionNames.UpdateStatus)
            :   false;
    }

    public static hasViewCostsAndBenefitsPermission(): boolean {
        const userPermissions = this.getUserPermissions();
        return userPermissions
            ?   userPermissions.includes(PermissionNames.CostAndBenefitsView)
            :   false;
    }

    public static hasEditCostsAndBenefitsPermission(): boolean {
        const userPermissions = this.getUserPermissions();
        return userPermissions
            ?   userPermissions.includes(PermissionNames.CostAndBenefitsWrite)
            :   false;
    }

    private static getUserPermissions(): any {
        return store.getters[getters.GET_USER_PERMISSIONS];
    }

    private static userIsOwnerOfIdeas(): any {
        return store.getters[getters.GET_USER_IS_OWNER_OF_IDEAS];
    }

    private static getIdeaCostsAndBenefits(): any {
        return store.getters[getters.GET_IDEA_COSTS_AND_BENEFITS];
    }

    private static getIdeaProjectManagerId(): string {
        return this.getIdeaCostsAndBenefits()?.projectManagerId;
    }

    private static getIdeaBusinessOwnerId(): string {
        return this.getCurrentIdea()?.businessOwnerId;
    }

    private static getIdeaProductOwnerId(): string {
        return this.getCurrentIdea()?.productOwnerId;
    }

    private static getUserKid(): string {
        return store.getters[getters.GET_USER_KID];
    }

    private static getUserDepartment(): string {
        return store.getters[getters.GET_USER_DEPARTMENT];
    }

    private static getCurrentIdea(): any {
        return store.getters[getters.GET_IDEA];
    }

    private static getIdeaBusinessDepartment(): string {
        return this.getCurrentIdea()?.businessDepartment;
    }

    public static ideaStatusIsLowerThanDeliveryApproved(ideaStatus: number | undefined): boolean {
        if (ideaStatus === undefined) return false;
        return ideaStatus < Status.DeliveryApproved;
    }

    private static ideaStatusIsLowerThanPoc(ideaStatus: number | undefined): boolean {
        if (ideaStatus === undefined) return false;
        return ideaStatus < Status.InPoC;
    }

    private static isIdeaStatusLowerThanMVP(ideaStatus: number | undefined): boolean {
        if (ideaStatus === undefined) return false;
        return ideaStatus < Status.InMVP;
    }

    public static isIdeaInitiator(ideaInitiatorKid: string | undefined): boolean {
        return this.getUserKid() === ideaInitiatorKid;
    }

    public static ideaStatusIsSubmitted(ideaStatus: number | undefined): boolean {
        return Status.Submitted === ideaStatus;
    }

    public static ideaStatusIsDraft(ideaStatus: number | undefined): boolean {
        return Status.Draft === ideaStatus;
    }

    private static isCoodeConnectorWithDeleteIdeaPermission(): boolean {
        return this.isCoodeConnector() && this.hasDeleteIdeaPermission();
    }

    public static isCoodeConnectorWithEditIdeaPermission(): boolean {
        return this.isCoodeConnector() && this.hasEditIdeaPermission();
    }

    private static hasDeleteIdeaPermission(): boolean {
        const userPermissions = this.getUserPermissions();
        return userPermissions
            ?   userPermissions.includes(PermissionNames.DeleteIdea)
            :   false;
    }

    private static hasEditIdeaPermission(): boolean {
        const userPermissions = this.getUserPermissions();
        return userPermissions
            ?   userPermissions.includes(PermissionNames.EditIdea)
            :   false;
    }

    public static isCoodeConnector(): boolean {
        const userPermissions = this.getUserPermissions();
        if (!userPermissions)
            return false;
        return userPermissions.includes(PermissionNames.Connector)
            || this.userBelongsToIdeaDepartment();
    }

    public static loggedInKidIs(kid: string): boolean {
        return this.getUserKid() === kid;
    }

    public static userBelongsToIdeaDepartment(ideaBusinessDepartment: string = this.getIdeaBusinessDepartment()): boolean {
        let userDepartment = this.getUserDepartment();

        if (!ideaBusinessDepartment || !userDepartment)
            return false;

        ideaBusinessDepartment = ideaBusinessDepartment.toLocaleLowerCase();
        userDepartment = userDepartment.toLocaleLowerCase();

        if (userDepartment.length < 5) {
            return ideaBusinessDepartment.includes(userDepartment);
        }

        if (ideaBusinessDepartment.length === 5) {
            return ideaBusinessDepartment === userDepartment;
        }

        return ideaBusinessDepartment.substring(0, 5) === userDepartment.substring(0, 5);
    }

    public static isIdeaProjectManager(): boolean {
        return this.getIdeaProjectManagerId() === this.getUserKid();
    }

    public static isIdeaProductOwner(): boolean {
        return this.getIdeaProductOwnerId() === this.getUserKid();
    }

    public static isIdeaBusinessOwner(): boolean {
        return this.getIdeaBusinessOwnerId() === this.getUserKid();
    }

    private static async loadPermissionsIfNotLoaded(): Promise<void> {
        const userPermissions: any = store.getters[getters.GET_USER_PERMISSIONS];
        if (!userPermissions?.length) {
            store.dispatch(actions.LOAD_USER_PROFILE);
        }
    }
}
