export class SelectOption {
    label?: string;
    secondaryLabel?: string;
    value?: number | string;
    email?: string;

    public constructor(data?: any) {
        this.label = data?.label ?? '';
        this.secondaryLabel = data?.secondaryLabel ?? '';
        this.value = data?.value ?? '';
        this.email = data?.email ?? '';
    }
}
