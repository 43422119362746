import { CostsAndBenefitsValueItemRunCostDto, CostsDto } from '@/service-proxies/service-proxies.g';
import { CostsAndBenefitsValueItem } from './costs-and-benefits-value-item';
import { CostsAndBenefitsValueItemRunCost } from './costs-and-benefits-value-item-run-cost';

export class Costs {
    changeCost: CostsAndBenefitsValueItem;
    runCosts?: CostsAndBenefitsValueItemRunCost[];

    public constructor(data?: CostsDto) {
        this.changeCost = new CostsAndBenefitsValueItem(data?.changeCost);
        if(!data?.runCosts?.length) {
            this.runCosts= [(new CostsAndBenefitsValueItemRunCost())]
        } else {
            this.runCosts = data?.runCosts && data?.runCosts?.map((cost: CostsAndBenefitsValueItemRunCostDto) =>
                new CostsAndBenefitsValueItemRunCost(cost)
            );
        }
    }
}
