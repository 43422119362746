import { Module } from 'vuex';
import { getters, mutations, actions } from '@/store/types';
import PermissionNames from '@/utils/permission-names';
import { LocalStorageHelper } from '@coode/fe-sdk';
import { UserProfile } from '@/models';

const user: Module<any, any> = {
    state: {
        user: new UserProfile(),
        permissions: [],
        users: [],
        userIsOwnerOfIdeas: false,
    },

    mutations: {
        [mutations.SET_USER_PROFILE](state, payload: any): void {
            state.user = payload;
        },
        [mutations.SET_USER_PERMISSIONS](state, payload: any): void {
            state.permissions = payload;
        },
        [mutations.SET_USERS](state, payload: any): void {
            state.users = payload;
        },
        [mutations.SET_USER_IS_OWNER_OF_IDEAS](state, payload: any): void {
            state.userIsOwnerOfIdeas = payload;
        },
    },

    //
    actions: {
        async [actions.LOAD_USER_PROFILE]({ commit }): Promise<any> {
            const userData: any = LocalStorageHelper.getPermissionsLocalStorage();
            const permissions = userData.permissions?.map((permission: any) => permission.name)?.toString();
            const user = new UserProfile(userData);

            commit(mutations.SET_USER_PERMISSIONS, permissions);
            commit(mutations.SET_USER_PROFILE, user);
        },
    },

    getters: {
        [getters.GET_USER]: (state) => state.user,
        [getters.GET_USER_KID]: (state) => state.user?.userKid,
        [getters.GET_USER_DEPARTMENT]: (state) => state.user?.department,
        [getters.GET_USER_PERMISSIONS]: ({ permissions }) => permissions,
        [getters.USER_IS_ADMIN]: ({ permissions }) => permissions ? permissions.includes(PermissionNames.Admin) : false,
        [getters.GET_USER_IS_OWNER_OF_IDEAS]: ({ userIsOwnerOfIdeas }) => userIsOwnerOfIdeas ?? false,
    },
    namespaced: true
}

export default {
    state: user.state,
    getters: user.getters,
    actions: user.actions,
    mutations: user.mutations
}
