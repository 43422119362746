import { AnswerCommentDto, AnswerDto, AnswerValueDto } from '@/service-proxies/service-proxies.g';
import { AnswerComment } from './answer-comment';
import { AnswerValue } from './answer-value';

export class Answer {
    id?: number;
    createdBy?: string;
    createdAt?: Date;
    updatedAt?: Date;
    updatedBy?: string;
    ideaId?: number;
    questionId?: number;
    content?: string;
    isReadOnly?: boolean;
    answerComments?: AnswerComment[];
    answerValues?: AnswerValue[];


    public constructor(data?: AnswerDto) {
        this.id = data?.id;
        this.ideaId = data?.ideaId;
        this.questionId = data?.questionId;
        if(data?.content)
            this.content = data?.content;
        this.answerComments = data?.answerComments?.map((answer: AnswerCommentDto) => new AnswerComment(answer));
        this.answerValues = data?.answerValues?.map((answer: AnswerValueDto) => new AnswerValue(answer));
    }
}
