import { CostsAndBenefitsGetDto, ServiceRecipientBusinessUnitDto } from '@/service-proxies/service-proxies.g';
import { ServiceRecipientBusinessUnit } from '../closure-report/service-recipient-business-unit';
import { Benefits } from './benefits';
import { Costs } from './costs';

export class CostsAndBenefits {
    projectStartDate?: string;
    projectEndDate?: string;
    strategicThemeId?: number;
    deliveryStrategyId?: number;
    deliveryTypeId?: number;
    deliveryOrganizationId?: number;
    serviceRecipientBusinessUnits?: ServiceRecipientBusinessUnit[];
    serviceEnablementContactId?: number;
    serviceEnablementContactComment?: string;
    serviceEnablementRequired?: boolean;
    domainArchitectId?: string;
    eamId?: number;
    costCenterId?: number;
    discountRate?: number;
    runCostAgreed?: boolean;
    benefits: Benefits;
    costs: Costs;
    projectManager?: string;
    projectManagerEmail?: string;
    projectManagerId?: string;

    public constructor(data?: CostsAndBenefitsGetDto) {
        const projectEndDate = new Date();
        projectEndDate.setFullYear(projectEndDate.getFullYear() + 5);

        this.projectStartDate = data?.projectStartDate?.toLocaleDateString() ?? new Date().toLocaleDateString();
        this.projectEndDate = data?.projectEndDate?.toLocaleDateString() ?? projectEndDate.toLocaleDateString();
        this.serviceRecipientBusinessUnits = data?.serviceRecipientBusinessUnits && data?.serviceRecipientBusinessUnits?.map(
            (unit: ServiceRecipientBusinessUnitDto) => new ServiceRecipientBusinessUnit(unit)
        );

        [
            'deliveryOrganizationId',
            'strategicThemeId',
            'deliveryStrategyId',
            'deliveryTypeId',
            'domainArchitectId',
            'eamId',
            'costCenterId',
            'discountRate',
            'runCostAgreed',
            'projectManager',
            'projectManagerEmail',
            'projectManagerId',
            'serviceEnablementContactId',
            'serviceEnablementContactComment',
            'serviceEnablementRequired'
        ]
            .filter(key => (data as any)[key])
            .forEach((key) => {
                (this as any)[key] = (data as any)[key];
            });

        this.benefits = new Benefits(data?.benefits);
        this.costs = new Costs(data?.costs);
    }
}
