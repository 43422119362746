import Vue from 'vue';

export const EventBus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
                SHOW_TOAST_MESSAGE: 'global.show-toast-message',
            },
            RADAR: {
                SHOW_FULLSCREEN: 'radar.show-fullscreen',
            }
        };
    },
});
