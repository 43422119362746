import { DeliveryStatusDto } from '@/service-proxies/service-proxies.g';

export class DeliveryStatus {
    id?: number;
    statusName?: string;

    public constructor(data?: DeliveryStatusDto) {
        this.id = data?.id;
        this.statusName = data?.statusName;
    }
}
