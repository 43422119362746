import { Module } from 'vuex';

import { ScreenServiceProxy } from '@/service-proxies/service-proxies.g';
import { IScreenListResponse } from '@/service-proxies/service-proxies.g';
import MessageHandler from '@/utils/message-handler';

const api = new ScreenServiceProxy();

const idea: Module<any, any> = {
    //
    state: {
        screens: [],
        score: [],
        maxScore: [],
    },

    //
    mutations: {
        setScreens(state, payload: IScreenListResponse): void {
            state.screens = payload.items;
        },
        setScore(state, payload: any): void {
            const existingScore = state.score.find((score: any) => score.id === payload.id)
            existingScore
                ? existingScore.value = payload.value
                : state.score.push({
                    id: payload.id,
                    value: payload.value,
                })
        },
        resetScore(state, payload = []): void {
            state.score = payload;
        },
    },

    //
    actions: {
        async getSubmitForm({ commit }, ideaId): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await api.listScreens(true, ideaId);
                if(!r) {
                    return;
                }
                commit('setScreens', r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        }
    },

    //
    getters: {
        //
    },
    namespaced: true
}

export default idea
