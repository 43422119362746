import { Module } from 'vuex';
import { actions, mutations, getters } from '../types';
import { AppService } from '@/services';
import { Application } from '@/models';
import MessageHandler from '@/utils/message-handler';

const appService = new AppService();

const useCases: Module<any, any> = {
    state: {
        useCases: []
    },

    mutations: {
        [mutations.SET_USECASES](state, payload: any): void {
            state.useCases = payload;
        },
    },

    actions: {
        async [actions.LOAD_USECASES]({ commit }): Promise<Application[]> {
            commit('loading', null, { root: true });
            try {
                const useCases = await appService.getApps();
                if (useCases && useCases?.items) {
                    commit(mutations.SET_USECASES, useCases.items);
                }
                return useCases?.items;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
                return [];
            } finally {
                commit('loading', null, { root: true });
            }
        },
    },

    getters: {
        [getters.GET_USECASES]: (state) => state.useCases,
    },
    namespaced: true
}

export default {
    state: useCases.state,
    getters: useCases.getters,
    actions: useCases.actions,
    mutations: useCases.mutations
}
