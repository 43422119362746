import { ClosureReportCostsDto } from '@/service-proxies/service-proxies.g';
import { CostsAndBenefitsItem } from './costs-and-benefits-item';
import { CostsAndBenefitsRunCostItem } from './costs-and-benefits-run-cost-item';

export class ActualCost {
    changeCosts?: CostsAndBenefitsItem;
    runCosts?: CostsAndBenefitsRunCostItem[];

    public constructor(data?: ClosureReportCostsDto) {
        this.changeCosts = new CostsAndBenefitsItem(data?.changeCosts);
        this.runCosts = data?.runCosts;
    }
}
