import { AnswerCommentDto } from '@/service-proxies/service-proxies.g';
import { Comment } from './comment';

export class AnswerComment {
    id?: number;
    createdBy?: string;
    createdAt?: Date;
    answerId?: number;
    commentId?: number;
    comment?: Comment;
    updatedAt?: Date;
    updatedBy?: string;


    public constructor(data?: AnswerCommentDto) {
        this.id = data?.id;
        this.answerId = data?.answerId;
        this.commentId = data?.commentId;
        this.comment = new Comment(data?.comment);
    }
}
