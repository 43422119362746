
















import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import './snackbar.less';

interface Response {
    response: any;
    statusCode: number;
    message?: string;
}

interface ErrorMessage {
    response?: Response;
    message?: string;
}

@Component({
    name: 'snackbar',
})
export default class Snackbar extends Vue {
    private defaultMessage = 'somethingWentWrong';
    private message = '';
    private duration = 5000;
    private isVisible = false;
    private timeout: any;

    private created(): void {
        EventBus.$on(EventBus.GLOBAL.SHOW_SNACKBAR, (message: ErrorMessage | string, overrideDefaultDuration: number | null) => {
            const responseBody = (message as any).response || message
            const statusCode = responseBody.statusCode || responseBody.status;

            function getMessage(message: any): any {
                return responseBody.messages && responseBody.messages[0] ||
                    responseBody.message || message.message
            }

            if (overrideDefaultDuration) {
                this.duration = overrideDefaultDuration;
            }
            this.message = statusCode === 500
                ? (this.$t(this.defaultMessage) as string)
                : typeof message === 'string' ? message : getMessage(message) || (this.$t(this.defaultMessage) as string)
            this.isVisible = true;
            this.timeout = setTimeout(() => {
                this.isVisible = false;
            }, this.duration);
        });
    }

    private dismiss(): void {
        this.isVisible = false;
        clearTimeout(this.timeout);
    }
}

