import { ServiceEnablementContactDto } from '@/service-proxies/service-proxies.g';

export class ServiceEnablementContact {
    id?: number;
    name?: string;
    kid?: string;

    public constructor(data?: ServiceEnablementContactDto) {
        this.id = data?.id;
        this.name = data?.name;
        this.kid = data?.kid;
    }
}
