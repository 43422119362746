import { Module } from 'vuex';
import {
    FinancialCostsServiceProxy,
    FinancialBenefitsServiceProxy,
    IUseCaseAllStagesDto,
    IUseCaseProofOfConceptDto,
    IUseCaseInDeliveryDto,
    IUseCaseInUseDto,
    DeliveryTypesServiceProxy,
    DeliveryTypeListDto,
    IdeaYearlyCostListResponse,
    IFinancialCostsGetOverviewResponse,
    IdeaMonthlyCostListResponse,
    IdeaYearlyAndQuarterlyCostDto,
    IGetOverviewResponse,
    IFinancialBenefitsUseCaseDto
} from '@/service-proxies/service-proxies.g';
import { DeliveryTeamService } from '@/services/delivery-team-service';
import { DeliveryTeam } from '@/models';
import { actions } from '../types';
import MessageHandler from '@/utils/message-handler';

const api = new FinancialCostsServiceProxy();
const deliveryTypesApi = new DeliveryTypesServiceProxy();
const deliveryTeamsApi = new DeliveryTeamService();
const benefitsOverviewApi = new FinancialBenefitsServiceProxy();

const financial: Module<any, any> = {
    //
    state: {
        statistics: [],
        benefitsStatistics: [],
        allStagesFinancialReports: [],
        proofOfConceptFinancialReports: [],
        inDeliveryFinancialReports: [],
        inUseFinancialReports: [],
        financialReports: [],
        financialEvaluationReportsMonthly: [],
        financialEvaluationReportsQuarterly: [],
        financialEvaluationReportsYearly: null,
        deliveryTypes: [],
        deliveryTeams: [],
        benefitsReports: []
    },

    //
    mutations: {

        setStatistics(state, payload: IFinancialCostsGetOverviewResponse): void {
            state.statistics = payload;
        },

        setAllStagesFinancialReports(state, allStagesFinancialReports: IUseCaseAllStagesDto[]): void {
            state.allStagesFinancialReports = allStagesFinancialReports
        },

        setProofOfConceptFinancialReports(state, proofOfConceptFinancialReports: IUseCaseProofOfConceptDto[]): void {
            state.proofOfConceptFinancialReports = proofOfConceptFinancialReports
        },

        setInDeliveryFinancialReports(state, inDeliveryFinancialReports: IUseCaseInDeliveryDto[]): void {
            state.inDeliveryFinancialReports = inDeliveryFinancialReports
        },

        setInUseFinancialReports(state, inUseFinancialReports: IUseCaseInUseDto[]): void {
            state.inUseFinancialReports = inUseFinancialReports
        },

        setFinancialEvaluationReportsMonthly(state, financialEvaluationReports: IdeaMonthlyCostListResponse[]): void {
            state.financialEvaluationReportsMonthly = financialEvaluationReports
        },

        setFinancialEvaluationReportsQuarterly(state, financialEvaluationReports: IdeaYearlyAndQuarterlyCostDto[]): void {
            state.financialEvaluationReportsQuarterly = financialEvaluationReports
        },

        setFinancialEvaluationReportsYearly(state, financialEvaluationReports: IdeaYearlyCostListResponse): void {
            state.financialEvaluationReportsYearly = financialEvaluationReports
        },
        setDeliveryTypes(state, deliveryTypes: DeliveryTypeListDto[]): void {
            state.deliveryTypes = deliveryTypes
        },
        setDeliveryTeams(state, deliveryTeams: DeliveryTeam[]): void {
            state.deliveryTeams = deliveryTeams
        },

        setBenefitsStatistics(state, payload: IGetOverviewResponse): void {
            state.benefitsStatistics = payload;
        },

        setBenefitsReports(state, benefitsReports: IFinancialBenefitsUseCaseDto[]): void {
            state.benefitsReports = benefitsReports
        }
    },
    //
    actions: {
        async  [actions.LOAD_STATISTICS]({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const response = await api.getFinancialCostsOverview();
                if(!response) {
                    return;
                }
                commit('setStatistics', response.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_ALL_STAGES_REPORTS]({ commit }, payload): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const { onlyOverBudget, deliveryTypes, deliveryTeams, searchTerm } = payload;
                const response = await api.listFinancialCostsStages(onlyOverBudget, searchTerm, deliveryTypes, deliveryTeams);
                if (!response) {
                    return;
                }
                commit('setAllStagesFinancialReports', response.result?.useCases);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_PROOF_OF_CONCEPT_REPORTS]({ commit }, payload): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const { deliveryTypes, deliveryTeams } = payload;
                const response = await api.listFinancialCostsProofOfConcept(deliveryTypes, deliveryTeams);
                if (!response) {
                    return;
                }
                commit('setProofOfConceptFinancialReports', response.result?.useCases);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_IN_DELIVERY_REPORTS]({ commit }, payload): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const { onlyOverBudget, deliveryTypes, deliveryTeams } = payload;
                const response = await api.listFinancialCostsInDelivery(onlyOverBudget, deliveryTypes, deliveryTeams);
                if (!response) {
                    return;
                }
                commit('setInDeliveryFinancialReports', response.result?.useCases);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_IN_USE_REPORTS]({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const response = await api.listFinancialCostsInUse();
                if (!response) {
                    return;
                }
                commit('setInUseFinancialReports', response.result?.useCases);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_MONTHLY_EVALUATION_REPORTS]({ commit }, {ideaId}): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const response = await api.getIdeaFinancialMonthlyCosts(ideaId);
                if (!response) {
                    return;
                }
                commit('setFinancialEvaluationReportsMonthly', response.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_QUARTERLY_EVALUATION_REPORTS]({ commit }, {ideaId}): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await api.getIdeaFinancialQuarterlyCosts(ideaId);
                if (!r || !r.result) {
                    return;
                }

                commit('setFinancialEvaluationReportsQuarterly', r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_YEARLY_EVALUATION_REPORTS]({ commit }, {ideaId}): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await api.getIdeaFinancialYearlyCosts(ideaId);
                if (!r) {
                    return;
                }
                commit('setFinancialEvaluationReportsYearly', r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_DELIVERY_TYPES]({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const response = await deliveryTypesApi.listDeliveryType();
                if (!response) {
                    return;
                }
                commit('setDeliveryTypes', response.result?.items);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_DELIVERY_TEAMS]({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const response = await deliveryTeamsApi.getDeliveryTeams();
                if (!response) {
                    return;
                }
                commit('setDeliveryTeams', response?.items);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_BENEFITS_STATISTICS]({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const response = await benefitsOverviewApi.getFinancialBenefitsOverview();
                if (!response) {
                    return;
                }
                commit('setBenefitsStatistics', response?.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_ALL_STAGES_BENEFITS_REPORTS]({ commit }, payload ): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const { stage, realized, term, deliveryOrganization, status, startDate, endDate } = payload;
                const response = await benefitsOverviewApi.getFinancialBenefitsAllStages(stage, realized, term, deliveryOrganization, status, startDate, endDate);
                if (!response) {
                    return;
                }
                commit('setBenefitsReports', response.result?.useCases);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },
    },

    //
    getters: {
        //
    },
    namespaced: true
}

export default {
    state: financial.state,
    getters: financial.getters,
    actions: financial.actions,
    mutations: financial.mutations
}
