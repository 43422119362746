import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import dashboard from './modules/dashboard';
import ideas from './modules/ideas';
import idea from './modules/idea';
import financial from './modules/financial';
import useCases from './modules/use-cases';

Vue.use(Vuex);

const applicationState: {
    isLoading: boolean;
} = {
    isLoading: false,
};

export default new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
    },
    actions: {
        //
    },
    modules: {
        user,
        dashboard,
        ideas,
        idea,
        financial,
        useCases
    },
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
    },
});
