import { Module } from 'vuex';

import { BusinessOwnerListResponse, CostTypeListDto, CostTypesServiceProxy, DepartmentListResponse, IdeaRelatedIdeaDto, IdeasServiceProxy, ProductOwnerListResponse, ServiceEnablementContactDto, ServiceEnablementContactsServiceProxy, ServiceRecipientBusinessUnitDto, DeliveryStatusesServiceProxy, DeliveryStatusDto } from '@/service-proxies/service-proxies.g';
import {
    IIdeaListResponse,
    IIdeaGetResponse,
    IdeaAddResponse,
    IdeaActionRequest,
    IdeaActionResponse,
    IAnswerNestedEditDto,
    AnswerNestedCreateDto,
    AnswerNestedEditDto,
    IdeaCommentServiceProxy,
    IdeaCommentAddResponse,
    /* eslint-disable @typescript-eslint/camelcase */
    ProductOwnerServiceProxy,
    BusinessOwnerServiceProxy,
    DepartmentsServiceProxy,
    /* eslint-enable @typescript-eslint/camelcase */
    EditAdminResponse,
    CostsAndBenefitsEditDto,
    RealizedAndScaledBenefitsGetDto,
    BenefitTypesServiceProxy, BenefitTypeListDto,
    ServiceRecipientBusinessUnitsServiceProxy
} from '@/service-proxies/service-proxies.g';
import { actions, mutations, getters } from '../types';
import { SelectOption, ServiceEnablementContact } from '@/models';
import { ClosureReportForm } from '@/models/closure-report/closure-report-form';
import { RelatedIdea } from '@/models/related-idea';
import { DeliveryStatus } from '@/models/delivery-status';
import MessageHandler from '@/utils/message-handler';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';
import { i18n } from '@/i18n-instance-file';


const api = new IdeasServiceProxy();
const ideaService = new IdeaCommentServiceProxy();
const productOwnerService = new ProductOwnerServiceProxy();
const businessOwnerService = new BusinessOwnerServiceProxy();
const businessDepartmentsService = new DepartmentsServiceProxy();
const benefitTypesApi = new BenefitTypesServiceProxy();
const costTypesApi = new CostTypesServiceProxy();
const serviceRecipientsUnitsApi = new ServiceRecipientBusinessUnitsServiceProxy();
const contactEnablementService = new ServiceEnablementContactsServiceProxy();
const deliveryStatusesApi = new DeliveryStatusesServiceProxy();

const STATUS_SUBMITTED = 2;

// const PAGE_SIZE = 10;

const ideas: Module<any, any> = {
    //
    state: {
        list: [],
        totalPages: 0,
        totalCount: 0,
        idea: {},
        businessOwners: [],
        productOwners: [],
        businessDepartments: [],
        benefitTypes: [],
        costTypes: [],
        closureReport: new ClosureReportForm(),
        serviceRecipientsBusinessUnits: [],
        closureReportExport: {},
        relatedIdeas: [],
        enablementContacts: [],
        deliveryStatuses: [],
        isCostsAndBenefitsReadOnly: false
    },

    //
    mutations: {
        [mutations.SET_IDEA_LIST](state, payload: IIdeaListResponse): void {
            state.list = payload.items;
            state.totalPages = payload.totalPages;
            state.totalCount = payload.totalCount;
        },
        [mutations.SET_IDEA](state, payload: IIdeaGetResponse): void {
            state.idea = payload;
        },
        [mutations.NEW_IDEA](state, payload = {}): void {
            state.idea = payload;
        },
        [mutations.SET_BUSINESS_OWNERS](state, payload: BusinessOwnerListResponse): void {
            state.businessOwners = payload.items
        },
        [mutations.SET_PRODUCT_OWNERS](state, payload: ProductOwnerListResponse): void {
            state.productOwners = payload.items
        },
        [mutations.SET_BUSINESS_OWNERS_DEPARTMENTS](state, payload: DepartmentListResponse): void {
            state.businessDepartments = payload.items
        },
        [mutations.SET_IDEA_COSTS_AND_BENEFITS](state, costsAndBenefits: CostsAndBenefitsEditDto): void {
            state.idea.costsAndBenefits = costsAndBenefits;
        },
        [mutations.SET_IDEA_REALIZED_AND_SCALED_BENEFITS](state, realizedAndScaledBenefits: RealizedAndScaledBenefitsGetDto): void {
            state.idea.realizedAndScaledBenefits = realizedAndScaledBenefits;
        },
        [mutations.SET_BENEFIT_TYPES](state, benefitTypes: SelectOption): void {
            state.benefitTypes = benefitTypes;
        },
        [mutations.SET_COST_TYPES](state, costTypes: SelectOption): void {
            state.costTypes = costTypes;
        },
        [mutations.SET_CLOSURE_REPORT](state, payload: any): void {
            state.closureReport = payload;
        },
        [mutations.SET_SERVICE_RECIPIENTS_BUSINESS_UNITS](state, payload: any): void {
            state.serviceRecipientsBusinessUnits = payload;
        },
        [mutations.SET_CLOSURE_REPORT_EXPORT](state, payload: any): void {
            state.closureReportExport = payload;
        },
        [mutations.SET_RELATED_IDEAS](state, payload: any): void {
            state.relatedIdeas = payload;
        },
        [mutations.SET_ENABLEMENT_CONTACTS](state, payload: any): void {
            state.enablementContacts = payload;
        },
        [mutations.SET_DELIVERY_STATUSES](state, payload: any): void {
            state.deliveryStatuses = payload;
        },
        [mutations.SET_IS_COSTS_AND_BENEFITS_READ_ONLY](state, payload: any): void {
            state.isCostsAndBenefitsReadOnly = payload;
        },
    },

    //
    actions: {
        async [actions.LOAD_IDEAS_LIST]({ commit }, payload): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await api.listIdeas(...payload);
                if(!r) {
                    return;
                }
                commit(mutations.SET_IDEA_LIST, r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_IDEAS_LIST_POST]({ commit }, payload): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await api.listIdeasWithFilter(...payload);
                if(!r) {
                    return;
                }
                commit(mutations.SET_IDEA_LIST, r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_IDEA]({ commit }, id): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await api.getIdea(id);
                if(!r) {
                    return;
                }
                commit(mutations.SET_IDEA, r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.SAVE_IDEA]({ commit }, payload): Promise<IdeaAddResponse | undefined> {
            commit('loading', null, { root: true });
            try {
                const r = await api.addIdea(payload);
                if(!r) {
                    return;
                }
                commit(mutations.SET_IDEA, r.result);
                return r.result;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.EDIT_IDEA]({ commit }, payload): Promise<IdeaAddResponse | undefined> {
            commit('loading', null, { root: true });
            try {
                const r = await api.editIdea(payload.id, payload.data);
                if(!r) {
                    return;
                }

                MessageHandler.showToastMessage(i18n.t('successfullySaved') as string, ToastMessageTypes.SUCCESS);
                commit(mutations.SET_IDEA, r.result);
                return r.result;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        [actions.EDIT_IDEA_COSTS_AND_BENEFITS]({ commit }, costsAndBenefits): void {
            commit(mutations.SET_IDEA_COSTS_AND_BENEFITS, costsAndBenefits);
        },

        [actions.EDIT_IDEA_REALIZED_AND_SCALED_BENEFITS]({ commit }, realizedAndScaledBenefits): void {
            commit(mutations.SET_IDEA_REALIZED_AND_SCALED_BENEFITS, realizedAndScaledBenefits);
        },

        [actions.EDIT_COSTS_AND_BENEFITS_READ_ONLY]({ commit }, payload): void {
            commit(mutations.SET_IS_COSTS_AND_BENEFITS_READ_ONLY, payload);
        },

        async [actions.SUBMIT_IDEA]({ commit }, payload): Promise<IdeaActionResponse | undefined> {
            commit('loading', null, { root: true });

            const idea = {
                id: payload.id,
                answers: payload.answers.map((answer: IAnswerNestedEditDto) => payload.id
                    ? new AnswerNestedEditDto(answer)
                    : new AnswerNestedCreateDto(answer)
                ),
                newStatus: payload.newStatus || STATUS_SUBMITTED,
                costsAndBenefits: payload.costsAndBenefits,
                realizedAndScaledBenefits: payload.realizedAndScaledBenefits,
            }

            try {
                const r = await api.action(payload.id, new IdeaActionRequest(idea));
                if(!r) {
                    return;
                }
                commit(mutations.SET_IDEA, r.result);
                if(idea.newStatus === 'DeliveryApproved') {
                    commit(mutations.SET_IS_COSTS_AND_BENEFITS_READ_ONLY, true);
                }
                return r.result;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.SUBMIT_IDEA_COMMENT]({ commit }, payload): Promise<IdeaCommentAddResponse | undefined> {
            commit('loading', null, { root: true });
            try {
                const r = await ideaService.addIdeaComment(payload);
                if(!r) {
                    return;
                }
                return r.result;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.DELETE_IDEA_COMMENT]({ commit }, commentId): Promise<boolean> {
            commit('loading', null, { root: true });
            try {
                return !!await ideaService.deleteIdeaComment(commentId);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }

            return false;
        },

        async [actions.EDIT_ONE_PAGER]({ commit }, payload): Promise<EditAdminResponse | undefined> {
            commit('loading', null, { root: true });
            try {
                const r = await api.editOnePager(payload.id, payload.data);
                if(!r) {
                    return;
                }
                commit(mutations.SET_IDEA,r.result)
                return r.result;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
                throw error;
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.DELETE_IDEA]({ commit }, ideaID): Promise<boolean> {
            commit('loading', null, { root: true });
            try {
                const r = await api.deleteIdea(ideaID);
                if(!r) {
                    return false;
                }
                return true;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }

            return false
        },

        async [actions.GET_PRODUCT_OWNERS]({ commit }, payload): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await productOwnerService.listProductOwners(payload.page, payload.size, payload.term);
                if(!r) {
                    return;
                }
                commit(mutations.SET_PRODUCT_OWNERS, r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.GET_BUSINESS_OWNERS]({ commit }, payload): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await businessOwnerService.listBusinessOwners(payload.page, payload.size, payload.term);
                if(!r) {
                    return;
                }
                commit(mutations.SET_BUSINESS_OWNERS, r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.GET_BUSINESS_OWNERS_DEPARTMENTS]({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await businessDepartmentsService.listDepartments();
                if(!r) {
                    return;
                }
                commit(mutations.SET_BUSINESS_OWNERS_DEPARTMENTS, r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.MARK_IDEA_AS_READ]({ commit }, ideaId): Promise<void> {
            commit('loading', null, { root: true });
            try {
                await api.markAsRead(ideaId);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_BENEFIT_TYPES]({ commit }): Promise<void> {
            try {
                const benefitTypesResult = await benefitTypesApi.listBenefitType();
                if (benefitTypesResult?.result?.items) {
                    const benefitTypes = benefitTypesResult?.result?.items?.map((item: BenefitTypeListDto) => ({
                        label: item.title,
                        value: item.id
                    }));
                    commit(mutations.SET_BENEFIT_TYPES, benefitTypes);
                }
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            }
        },

        async [actions.LOAD_COST_TYPES]({ commit }): Promise<void> {
            try {
                const costTypesResult = await costTypesApi.listCostType();
                if (costTypesResult?.result?.items) {
                    const costTypes = costTypesResult?.result?.items?.map((item: CostTypeListDto) => ({
                        label: item.title,
                        value: item.id
                    }));
                    commit(mutations.SET_COST_TYPES, costTypes);
                }
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            }
        },

        async [actions.SUBMIT_CLOSURE_REPORT]({ commit }, payload): Promise<any> {
            commit('loading', null, { root: true });
            try {
                await api.closureReportAdd(payload);
                return true;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
                return false;
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_CLOSURE_REPORT]({ commit }, id): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await api.closureReportGetDetails(id);
                if(!r) {
                    return;
                }
                commit(mutations.SET_CLOSURE_REPORT, new ClosureReportForm(r.result));
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.SIGN_CLOSURE_REPORT]({ commit }, payload): Promise<any> {
            commit('loading', null, { root: true });
            try {
                const {ideaId} = payload;
                await api.closureReportSign(ideaId,payload);
                return true;
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
                return false;
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.EXPORT_CLOSURE_REPORT]({ commit }, id): Promise<any> {
            commit('loading', null, { root: true });
            try {
                const r = await api.exportClosureReportToPdf(id);
                if(!r) {
                    return;
                }
                commit(mutations.SET_CLOSURE_REPORT_EXPORT, r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_SERVICE_RECIPIENTS_BUSINESS_UNITS]({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const serviceRecipientsUnits = await serviceRecipientsUnitsApi.closureReportGetDetails2();
                if (serviceRecipientsUnits?.result?.data) {
                    const units = serviceRecipientsUnits?.result?.data?.map((item: ServiceRecipientBusinessUnitDto) => ({
                        label: item.name,
                        value: item.id
                    }));
                    commit(mutations.SET_SERVICE_RECIPIENTS_BUSINESS_UNITS, units);
                }
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_RELATED_IDEAS]({ commit }, id): Promise<RelatedIdea[]> {
            try {
                const relatedIdeas = await api.getIdeaRelatedIdeas(id);
                if (relatedIdeas?.result?.items) {
                    const ideas = relatedIdeas?.result?.items?.map(
                        (item: IdeaRelatedIdeaDto) => new RelatedIdea(item)
                    );
                    commit(mutations.SET_RELATED_IDEAS, ideas);
                    return ideas;
                }
                return [];
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
                return [];
            }
        },

        async [actions.ADD_RELATED_USECASE]({ commit }, payload): Promise<void> {
            commit('loading', null, { root: true });
            try {
                await api.addRelatedUseCase(payload.id, payload.useCaseId);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },

        async [actions.LOAD_ENABLEMENT_CONTACTS]({ commit }): Promise<void> {
            try {
                const contactsList = await contactEnablementService.listServiceEnablementContact();
                if (contactsList?.result?.data) {
                    const contacts = contactsList?.result?.data?.map(
                        (item: ServiceEnablementContactDto) => new ServiceEnablementContact(item)
                    );
                    commit(mutations.SET_ENABLEMENT_CONTACTS, contacts);
                }
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            }
        },

        async [actions.LOAD_DELIVERY_STATUSES]({ commit }): Promise<void> {
            try {
                const deliveryStatuses = await deliveryStatusesApi.listDeliveryStatuses();
                if (deliveryStatuses?.result?.data) {
                    const statuses = deliveryStatuses?.result?.data?.map(
                        (item: DeliveryStatusDto) => new DeliveryStatus(item)
                    );
                    commit(mutations.SET_DELIVERY_STATUSES, statuses);
                }
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            }
        },
    },

    getters: {
        [getters.GET_IDEA]: (state) =>  state.idea,
        [getters.GET_IDEA_COSTS_AND_BENEFITS]: (state) =>  state.idea.costsAndBenefits,
        [getters.GET_IDEA_COSTS_AND_BENEFITS_START_DATE]: (state) =>  state.idea?.costsAndBenefits?.projectStartDate,
        [getters.GET_IDEA_REALIZED_AND_SCALED_BENEFITS]: (state) =>  state.idea?.realizedAndScaledBenefits,
        [getters.GET_IDEA_STATUS_ID]: (state) =>  state.idea?.statusId,
        [getters.GET_BENEFIT_TYPES]: (state) => state.benefitTypes,
        [getters.GET_COST_TYPES]: (state) => state.costTypes,
        [getters.GET_CLOSURE_REPORT]: (state) => state.closureReport,
        [getters.GET_CLOSURE_REPORT_EXPORT]: (state) => state.closureReportExport,
        [getters.GET_SERVICE_RECIPIENTS_BUSINESS_UNITS]: (state) => state.serviceRecipientsBusinessUnits,
        [getters.GET_RELATED_IDEAS]: (state) => state.relatedIdeas,
        [getters.GET_ENABLEMENT_CONTACTS]: (state) => state.enablementContacts,
        [getters.GET_DELIVERY_STATUSES]: (state) => state.deliveryStatuses,
        [getters.GET_IS_COSTS_AND_BENEFITS_READ_ONLY]: (state) => state.isCostsAndBenefitsReadOnly,
    },
    namespaced: true
}

export default {
    state: ideas.state,
    getters: ideas.getters,
    actions: ideas.actions,
    mutations: ideas.mutations
}
