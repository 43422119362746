


























































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { Application, UserProfile } from '@/models';
import NavigationManagement from '@/components/navigation-management/navigation-management.vue';
import { actions, getters } from '@/store/types';
import { mapGetters } from 'vuex';

@Component({
    name: 'header-wrapper',
    components: {
        NavigationManagement,
    },
    computed: mapGetters({
        useCases: getters.GET_USECASES,
        user: getters.GET_USER,
    }),
})
export default class HeaderWrapper extends Vue {
    private applications: MenuEntry[] = [];

    private searchQuery = '';

    private useCases!: Array<any>;
    private user!: UserProfile;

    private appStoreUrl?: string = process.env.VUE_APP_APP_STORE_UI_BASE_URL;
    private useCaseId?: string = process.env.VUE_APP_USE_CASE_ID;

    @Watch('$route.query.q')
    onSearchQueryChanged(term: string): void {
        this.searchQuery = term;
    }

    private async mounted(): Promise<void> {
        this.searchQuery = (this.$route.query.q as string);
        await this.init();
    }

    private async init(): Promise<void> {
        await this.$store.dispatch(actions.LOAD_USER_PROFILE);
        await this.$store.dispatch(actions.LOAD_USECASES);
        this.loadApps();
        if (this.$route.name !== null) {
            await this.$store.dispatch('dashboard/getUseCases');
        }
        await this.$store.dispatch('dashboard/getUseCases');
    }

    private async loadApps(): Promise<void> {
        if(this.useCases && this.useCases?.length) {
            this.applications = this.processApps(this.useCases);
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.id.toString(),
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private logOut(): void {
        this.$sdk.$authenticator?.logout();
    }

    private sendToIncident(): void {
        if (!this.appStoreUrl || !this.useCaseId) {
            return;
        }

        window.open(`${this.appStoreUrl}incident-page/${this.useCaseId}`, '_blank');
    }

    private sendToFeedback(): void {
        if (!this.appStoreUrl || !this.useCaseId) {
            return;
        }

        window.open(`${this.appStoreUrl}contact-page/${this.useCaseId}?isFeedback=true`, '_blank');
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$t('appsWithAccess'), miscellaneous: this.$t('appsWithoutAccess') };
    }

    private removeSearch(): void {
        this.searchQuery = '';
        this.setQuery('');
    }

    private setSearch(term: string): void {
        this.searchQuery = term;
    }

    private setQuery(term: string): void {
        this.$router.replace({
            query: {
                ...this.$route.query,
                q: term,
            }
        })
    }

    private get shouldShowFeedbackButton(): boolean {
        return !!this.appStoreUrl && !!this.useCaseId;
    }

    private get isDevelopmentEnv(): boolean {
        return ['dev', 'development'].includes(process.env.VUE_APP_ENVIRONMENT);
    }
    private get isUatEnv(): boolean {
        return process.env.VUE_APP_ENVIRONMENT === 'uat';
    }
}
