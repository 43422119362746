import { CostsAndBenefitsValueItemRunCostDto } from '@/service-proxies/service-proxies.g';

export class CostsAndBenefitsValueItemRunCost {
    comment?: string;
    year0?: number;
    year1?: number;
    year2?: number;
    year3?: number;
    year4?: number;
    year5?: number;
    costTypeId?: number;


    public constructor(data?: CostsAndBenefitsValueItemRunCostDto) {
        if(this.comment)
            this.comment = data?.comment;
        this.year0 = data?.year0 ?? 0;
        this.year1 = data?.year1 ?? 0;
        this.year2 = data?.year2 ?? 0;
        this.year3 = data?.year3 ?? 0;
        this.year4 = data?.year4 ?? 0;
        this.year5 = data?.year5 ?? 0;
        this.costTypeId = data?.costTypeId;
    }
}
