















import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Loader extends Vue {

    constructor() {
        super();
    }

    get isLoading(): boolean {
        return this.$store.getters.isLoading;
    }
}

