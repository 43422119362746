import { IdeaRelatedIdeaDto } from '@/service-proxies/service-proxies.g';

export class RelatedIdea {
    id?: number;
    title?: string;
    coodeId?: string;
    detailsLink?: string;

    public constructor(data?: IdeaRelatedIdeaDto) {
        this.id = data?.id;
        this.title = data?.title;
        this.coodeId = data?.coodeId;
        this.detailsLink = data?.detailsLink;
    }
}
