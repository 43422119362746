import { AnswerValueCommentDto } from '@/service-proxies/service-proxies.g';
import { Comment } from './comment';

export class AnswerValueComment {
    id?: number;
    createdBy?: string;
    createdAt?: Date;
    answerValueId?: number;
    commentId?: number;
    comment?: Comment;
    updatedAt?: Date;
    updatedBy?: string;


    public constructor(data?: AnswerValueCommentDto) {
        this.id = data?.id;
        this.answerValueId = data?.answerValueId;
        this.commentId = data?.commentId;
        this.comment = new Comment(data?.comment);
    }
}
