import { Module } from 'vuex';
import store from '@/store';
import { mutations } from '@/store/types';
import {
    DashboardServiceProxy,
    StatusServiceProxy,
    PhaseServiceProxy,
    IDashboardModelGetResponse
} from '@/service-proxies/service-proxies.g';
import {
    IStatusListResponse,
    IPhaseListResponse
} from '@/service-proxies/service-proxies.g';
import MessageHandler from '@/utils/message-handler';

const dashboardService = new DashboardServiceProxy();
const statusService = new StatusServiceProxy();
const phaseService = new PhaseServiceProxy();

const dashboard: Module<any, any> = {
    //
    state: {
        phases: [],
        statuses: [],
        groupedUseCases: [],
        useCases: [],
        ideas: [],
    },

    //
    mutations: {
        setPhases(state, payload: IPhaseListResponse): void {
            state.phases = payload.items;
        },
        setStatuses(state, payload: IStatusListResponse): void {
            state.statuses = payload.items;
        },
        setUseCases(state, payload: IDashboardModelGetResponse): void {
            state.groupedUseCases = payload.phases;
        },
    },

    //
    actions: {
        async getPhases({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await phaseService.listPhases();
                if(!r) {
                    return;
                }
                commit('setPhases', r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async getStatuses({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await statusService.listStatuses(true, 1, 100);
                if(!r) {
                    return;
                }
                commit('setStatuses', r.result);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },
        async getUseCases({ commit }): Promise<void> {
            commit('loading', null, { root: true });
            try {
                const r = await dashboardService.getDashboard();
                if(!r) {
                    return;
                }
                commit('setUseCases', r.result);
                store.commit(mutations.SET_USER_IS_OWNER_OF_IDEAS, r.result?.userIsOwnerOfIdeas);
            } catch(error: any) {
                MessageHandler.showErrorMessage(error);
            } finally {
                commit('loading', null, { root: true });
            }
        },
    },

    //
    getters: {
        totalNrOfUsecases: state => {
            return state.groupedUseCases
                .reduce((total: any, currentPhase: { count: any }) => total += currentPhase.count, 0)
        },
    },
    namespaced: true
}

export default dashboard
