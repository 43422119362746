import { ErrorResponse } from '@/models';
import { EventBus } from '@/utils/eventbus';
import { ToastMessageTypes } from '@/mixins/toast-message/toast-message-types';

export default class MessageHandler {
    public static showErrorMessage(error: ErrorResponse): void {
        if (error.statusCode === 400) {
            const errorMessages =  error?.errors?.join('\r\n');
            this.showToastMessage(errorMessages, ToastMessageTypes.ERROR);
        } else {
            this.showToastMessage(error?.message, ToastMessageTypes.ERROR);
        }
    }

    static showToastMessage(message: string | undefined, messageType: ToastMessageTypes, duration?: number, messageTitle?: string): void {
        EventBus.$emit(EventBus.GLOBAL.SHOW_TOAST_MESSAGE, message, messageType, duration, messageTitle);
    }
}
