import { ClosureReportCostAndBenefitsDto } from '@/service-proxies/service-proxies.g';
import { ActualCost } from './actual-cost';
import { ClosureReportBenefit } from './closure-report-benefit';

export class CostsAndBenefitsClosureReport {
    tangibleBenefits?: ClosureReportBenefit;
    actualCosts?: ActualCost;

    public constructor(data?: ClosureReportCostAndBenefitsDto) {
        this.tangibleBenefits = new ClosureReportBenefit(data?.tangibleBenefits);
        this.actualCosts = new ActualCost(data?.actualCosts);
    }
}
