import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import { i18n } from '@/i18n-instance-file';
import { CoodeSdkPlugin, CoodeSdkVueHttpPluginOption, TelemetryPlugin } from '@coode/fe-sdk';
import coodeSdkPluginConfiguration from '@/utils/coode-sdk-plugin-configuration';

Vue.config.productionTip = false;
Vue.use(PebbleUI as any);

Vue.use(
    new TelemetryPlugin(),
    {
        appInsightsConnectionString: process.env.VUE_APP_APP_INSIGHTS_CONNECTION_STRING
    } as CoodeSdkVueHttpPluginOption
);

Vue.use(new CoodeSdkPlugin(), coodeSdkPluginConfiguration);

new Vue({
    i18n,
    router,
    store,
    render: (h): VNode => h(App),
}).$mount('#app');
