



































import { Component, Vue } from 'vue-property-decorator';
import Permissions from '@/utils/helpers/permisssions';
const PAGE_NAME = {
    HOME: 'Home',
    FINANCIAL: 'Financial',
}
@Component({
    name: 'navigation-management'
})
export default class NavigationManagement extends Vue {
    hideFinancial: boolean = JSON.parse(process.env.VUE_APP_HIDE_FINANCIAL)

    get canViewFinancial(): boolean {
        return Permissions.canViewFinancial();
    }

    get isOverviewView(): boolean {
        return PAGE_NAME.HOME === this.$route.name;
    }

    get isFinancialView(): boolean {
        return PAGE_NAME.FINANCIAL === this.$route.name;
    }
}
