import { CostsAndBenefitsValueItemBenefitDto, RealizedAndScaledBenefitsBaseDto } from '@/service-proxies/service-proxies.g';
import { CostsAndBenefitsValueItem } from '../costs-and-benefits/costs-and-benefits-value-item';
import { CostsAndBenefitsValueItemBenefit } from '../costs-and-benefits/costs-and-benefits-value-item-benefit';

export class RealizedAndScaledBenefitsBase {
    indirectBenefits?: CostsAndBenefitsValueItemBenefit[];
    revenue?: CostsAndBenefitsValueItem;
    opex?: CostsAndBenefitsValueItem;
    capex?: CostsAndBenefitsValueItem;

    public constructor(data?: RealizedAndScaledBenefitsBaseDto) {
        this.indirectBenefits = data?.indirectBenefits?.length ? data?.indirectBenefits?.map((benefit: CostsAndBenefitsValueItemBenefitDto) =>
            new CostsAndBenefitsValueItemBenefit(benefit)) : [new CostsAndBenefitsValueItemBenefit()];
        this.revenue = new CostsAndBenefitsValueItem(data?.revenue);
        this.opex = new CostsAndBenefitsValueItem(data?.opex);
        this.capex = new CostsAndBenefitsValueItem(data?.capex);
    }
}
