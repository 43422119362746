import { IdeaServiceRecipientBusinessUnitDto } from '@/service-proxies/service-proxies.g';

export class ServiceRecipientBusinessUnit {
    invoicePercent?: number;
    serviceRecipientBusinessUnitId?: number;

    public constructor(data?: IdeaServiceRecipientBusinessUnitDto) {
        this.invoicePercent = data?.invoicePercent;
        this.serviceRecipientBusinessUnitId = data?.serviceRecipientBusinessUnitId;
    }
}
