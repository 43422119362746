import { AnswerValueCommentDto, AnswerValueDto } from '@/service-proxies/service-proxies.g';
import { AnswerValueComment } from './answer-value-comment';

export class AnswerValue {
    id?: number;
    createdBy?: string;
    createdAt?: Date;
    updatedAt?: Date;
    updatedBy?: string;
    answerId?: number;
    valueId?: number;
    additionalValue?: string;
    answerValueComments?: AnswerValueComment[];


    public constructor(data?: AnswerValueDto) {
        this.id = data?.id;
        this.answerId = data?.answerId;
        this.valueId = data?.valueId;
        this.answerValueComments = data?.answerValueComments ? data?.answerValueComments?.map((answer: AnswerValueCommentDto) => new AnswerValueComment(answer)) : [];
    }
}
