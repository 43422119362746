export const actions = {
    LOAD_STATISTICS: 'financial/LOAD_STATISTICS' as string,
    LOAD_ALL_STAGES_REPORTS : 'financial/LOAD_ALL_STAGES_REPORTS' as string,
    LOAD_PROOF_OF_CONCEPT_REPORTS : 'financial/LOAD_PROOF_OF_CONCEPT_REPORTS' as string,
    LOAD_IN_DELIVERY_REPORTS : 'financial/LOAD_IN_DELIVERY_REPORTS' as string,
    LOAD_IN_USE_REPORTS : 'financial/LOAD_IN_USE_REPORTS' as string,
    LOAD_MONTHLY_EVALUATION_REPORTS : 'financial/LOAD_MONTHLY_EVALUATION_REPORTS' as string,
    LOAD_QUARTERLY_EVALUATION_REPORTS : 'financial/LOAD_QUARTERLY_EVALUATION_REPORTS' as string,
    LOAD_YEARLY_EVALUATION_REPORTS : 'financial/LOAD_YEARLY_EVALUATION_REPORTS' as string,
    LOAD_DELIVERY_TYPES: 'financial/LOAD_DELIVERY_TYPES' as string,
    LOAD_DELIVERY_TEAMS: 'financial/LOAD_DELIVERY_TEAMS' as string,
    LOAD_BENEFITS_STATISTICS: 'financial/LOAD_BENEFITS_STATISTICS' as string,
    LOAD_ALL_STAGES_BENEFITS_REPORTS: 'financial/LOAD_ALL_STAGES_BENEFITS_REPORTS' as string,

    LOAD_IDEAS_LIST: 'ideas/LOAD_IDEAS_LIST' as string,
    LOAD_IDEAS_LIST_POST: 'ideas/LOAD_IDEAS_LIST_POST' as string,
    LOAD_IDEA: 'ideas/LOAD_IDEA' as string,
    SAVE_IDEA: 'ideas/SAVE_IDEA' as string,
    EDIT_IDEA: 'ideas/EDIT_IDEA' as string,
    EDIT_IDEA_COSTS_AND_BENEFITS: 'ideas/EDIT_IDEA_COSTS_AND_BENEFITS' as string,
    EDIT_IDEA_REALIZED_AND_SCALED_BENEFITS: 'ideas/EDIT_IDEA_REALIZED_AND_SCALED_BENEFITS' as string,
    SUBMIT_IDEA: 'ideas/SUBMIT_IDEA' as string,
    SUBMIT_IDEA_COMMENT: 'ideas/SUBMIT_IDEA_COMMENT' as string,
    SUBMIT_CLOSURE_REPORT: 'ideas/SUBMIT_CLOSURE_REPORT' as string,
    EDIT_ONE_PAGER: 'ideas/EDIT_ONE_PAGER' as string,
    DELETE_IDEA: 'ideas/DELETE_IDEA' as string,
    GET_PRODUCT_OWNERS: 'ideas/GET_PRODUCT_OWNERS' as string,
    GET_BUSINESS_OWNERS: 'ideas/GET_BUSINESS_OWNERS' as string,
    GET_BUSINESS_OWNERS_DEPARTMENTS: 'ideas/GET_BUSINESS_OWNERS_DEPARTMENTS' as string,
    MARK_IDEA_AS_READ: 'ideas/MARK_IDEA_AS_READ' as string,
    DELETE_IDEA_COMMENT: 'ideas/DELETE_IDEA_COMMENT' as string,
    LOAD_BENEFIT_TYPES: 'ideas/LOAD_BENEFIT_TYPES' as string,
    LOAD_COST_TYPES: 'ideas/LOAD_COST_TYPES' as string,
    LOAD_CLOSURE_REPORT: 'ideas/LOAD_CLOSURE_REPORT' as string,
    SIGN_CLOSURE_REPORT: 'ideas/SIGN_CLOSURE_REPORT' as string,
    LOAD_SERVICE_RECIPIENTS_BUSINESS_UNITS: 'ideas/LOAD_SERVICE_RECIPIENTS_BUSINESS_UNITS' as string,
    EXPORT_CLOSURE_REPORT: 'ideas/EXPORT_CLOSURE_REPORT' as string,
    LOAD_RELATED_IDEAS: 'ideas/LOAD_RELATED_IDEAS' as string,
    ADD_RELATED_USECASE: 'ideas/ADD_RELATED_USECASE' as string,
    LOAD_DELIVERY_STATUSES: 'ideas/LOAD_DELIVERY_STATUSES' as string,
    EDIT_COSTS_AND_BENEFITS_READ_ONLY: 'ideas/EDIT_COSTS_AND_BENEFITS_READ_ONLY' as string,

    LOAD_USECASES: 'useCases/LOAD_USECASES' as string,
    LOAD_ENABLEMENT_CONTACTS: 'ideas/LOAD_ENABLEMENT_CONTACTS' as string,

    LOAD_USER_PROFILE: 'user/LOAD_USER_PROFILE' as string,
};

export const mutations = {
    SET_IDEA: 'ideas/SET_IDEA' as string,
    SET_IDEA_COSTS_AND_BENEFITS: 'ideas/SET_IDEA_COSTS_AND_BENEFITS' as string,
    SET_IDEA_REALIZED_AND_SCALED_BENEFITS: 'ideas/SET_IDEA_REALIZED_AND_SCALED_BENEFITS' as string,
    SET_IDEA_LIST: 'ideas/SET_IDEA_LIST' as string,
    NEW_IDEA: 'ideas/NEW_IDEA' as string,
    SET_BUSINESS_OWNERS: 'ideas/SET_BUSINESS_OWNERS' as string,
    SET_PRODUCT_OWNERS: 'ideas/SET_PRODUCT_OWNERS' as string,
    SET_ENABLEMENT_CONTACTS: 'ideas/SET_ENABLEMENT_CONTACTS' as string,
    SET_IS_COSTS_AND_BENEFITS_READ_ONLY: 'ideas/SET_IS_COSTS_AND_BENEFITS_READ_ONLY' as string,

    SET_USER_PROFILE: 'user/SET_USER_PROFILE' as string,
    SET_USER_PERMISSIONS: 'user/SET_USER_PERMISSIONS' as string,
    SET_USERS: 'user/SET_USERS' as string,
    SET_USER_IS_OWNER_OF_IDEAS: 'user/SET_USER_IS_OWNER_OF_IDEAS' as string,
    SET_BUSINESS_OWNERS_DEPARTMENTS : 'ideas/SET_BUSINESS_OWNERS_DEPARTMENTS' as string,
    SET_BENEFIT_TYPES: 'ideas/SET_BENEFIT_TYPES' as string,
    SET_COST_TYPES: 'ideas/SET_COST_TYPES' as string,

    SET_CLOSURE_REPORT: 'ideas/SET_CLOSURE_REPORT' as string,
    SET_SERVICE_RECIPIENTS_BUSINESS_UNITS: 'ideas/SET_SERVICE_RECIPIENTS_BUSINESS_UNITS' as string,
    SET_CLOSURE_REPORT_EXPORT: 'ideas/SET_CLOSURE_REPORT_EXPORT' as string,
    SET_RELATED_IDEAS: 'ideas/SET_RELATED_IDEAS' as string,
    SET_DELIVERY_STATUSES: 'ideas/SET_DELIVERY_STATUSES' as string,

    SET_USECASES: 'useCases/SET_USECASES' as string,
}

export const getters = {
    GET_IDEA: 'ideas/GET_IDEA' as string,
    GET_IDEA_COSTS_AND_BENEFITS: 'ideas/GET_IDEA_COSTS_AND_BENEFITS' as string,
    GET_IDEA_COSTS_AND_BENEFITS_START_DATE: 'ideas/GET_IDEA_COSTS_AND_BENEFITS_START_DATE' as string,
    GET_IDEA_REALIZED_AND_SCALED_BENEFITS: 'ideas/GET_IDEA_REALIZED_AND_SCALED_BENEFITS' as string,
    GET_IDEA_STATUS_ID: 'ideas/GET_IDEA_STATUS_ID' as string,
    GET_BENEFIT_TYPES: 'ideas/GET_BENEFIT_TYPES' as string,
    GET_COST_TYPES: 'ideas/GET_COST_TYPES' as string,
    GET_IS_COSTS_AND_BENEFITS_READ_ONLY: 'ideas/GET_IS_COSTS_AND_BENEFITS_READ_ONLY' as string,

    GET_USER_KID: 'user/GET_USER_KID' as string,
    GET_USER_DEPARTMENT: 'user/GET_USER_DEPARTMENT' as string,
    GET_USER_PERMISSIONS: 'user/GET_USER_PERMISSIONS' as string,
    USER_IS_ADMIN: 'user/USER_IS_ADMIN' as string,
    GET_USER_IS_OWNER_OF_IDEAS: 'user/SET_USER_IS_OWNER_OF_IDEAS' as string,
    GET_USER: 'user/GET_USER' as string,

    GET_CLOSURE_REPORT: 'ideas/GET_CLOSURE_REPORT' as string,
    GET_CLOSURE_REPORT_EXPORT: 'ideas/GET_CLOSURE_REPORT_EXPORT' as string,
    GET_SERVICE_RECIPIENTS_BUSINESS_UNITS: 'ideas/GET_SERVICE_RECIPIENTS_BUSINESS_UNITS' as string,
    GET_RELATED_IDEAS: 'ideas/GET_RELATED_IDEAS' as string,
    GET_DELIVERY_STATUSES: 'ideas/GET_DELIVERY_STATUSES' as string,

    GET_USECASES: 'useCases/GET_USECASES' as string,
    GET_ENABLEMENT_CONTACTS: 'ideas/GET_ENABLEMENT_CONTACTS' as string,
}
