import { AnswerDto, IdeaDto } from '@/service-proxies/service-proxies.g';
import { Answer } from './answer';
import { CostsAndBenefits } from './costs-and-benefits/costs-and-benefits';
import { RealizedAndScaledBenefits } from './realized-and-scaled-benefits/realized-and-scaled-benefits';

export class IdeaForm {
    wbsElement?: string;
    newDeliveryEndDate?: Date;
    newDeliveryEndDateComment?: string;
    deliveryStatusId?: number;
    answers?: Answer[];
    costsAndBenefits?: CostsAndBenefits;
    realizedAndScaledBenefits?: RealizedAndScaledBenefits;

    public constructor(data?: IdeaDto, answers?: any) {
        this.answers = answers ? answers.map((answer: AnswerDto) => new Answer(answer)) : data?.answers?.map((answer: AnswerDto) => new Answer(answer));
        if(data?.costsAndBenefits && Object.values(data?.costsAndBenefits).length) {
            this.costsAndBenefits = new CostsAndBenefits(data?.costsAndBenefits);
        }
        if(data?.realizedAndScaledBenefits && Object.values(data?.realizedAndScaledBenefits).length)
            this.realizedAndScaledBenefits = new RealizedAndScaledBenefits(data?.realizedAndScaledBenefits);
        [
            'wbsElement',
            'newDeliveryEndDate',
            'newDeliveryEndDateComment',
            'deliveryStatusId',
        ]
            .filter(key => (data as any)[key])
            .forEach((key) => {
                (this as any)[key] = (data as any)[key];
            });
    }
}
