















import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Toolbar from '@/components/toolbar/toolbar.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import ToastMessage from '@/mixins/toast-message/toast-message';
import './main.less';
import { actions } from '@/store/types';

@Component({
    name: 'app',
    mixins: [ToastMessage],
    components: {
        HeaderWrapper,
        Toolbar,
        snackbar: Snackbar,
        loader: Loader,
    },
})
export default class App extends Vue {
    private useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
    private signedIn = false;

    private async created(): Promise<void> {
        this.loadAuth();
    }

    private async loadAuth(): Promise<void> {
        await (this as any).$sdk?.$authenticator?.loadAuthModule();
        await (this as any).$sdk?.$authenticator?.attemptSsoSilent('loginRedirect');

        await (this as any).$sdk?.$http?.$generic.request.api.setBaseUrl(process.env.VUE_APP_USER_ACCESS_MANAGEMENT_API_BASE_URL);
        await (this as any).$sdk?.$http?.$generic.request.api.setTokenCallback(async () => await (this as any).$sdk?.$authenticator?.getToken(process.env.VUE_APP_UAM_SCOPE));

        if (await this.$sdk?.$authenticator?.isAuthenticated() as boolean) {
            await this.$sdk?.$permissions?.setPermissions(this.useCaseId);
            await this.$store.dispatch(actions.LOAD_USER_PROFILE);
            this.signedIn = true;
        }
    }
}
