import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Permissions from '@/utils/helpers/permisssions';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home/:area?',
        name: 'Home',
        component: (): Promise<any> => import(/* webpackChunkName: "Home" */ '../pages/home/home.vue'),
    },
    {
        path: '/projects/:area?',
        name: 'Projects',
        component: (): Promise<any> => import(/* webpackChunkName: "Projects" */ '../pages/projects/projects.vue'),
    },
    {
        path: '/submit/:id?',
        name: 'Submit',
        component: (): Promise<any> => import(/* webpackChunkName: "Submit" */ '../pages/submit/submit.vue'),
    },
    {
        path: '/ideas/:id?',
        name: 'Idea',
        component: (): Promise<any> => import(/* webpackChunkName: "Submit" */ '../pages/ideas/idea.vue'),
    },
    {
        path: '/ideas/edit/:id?',
        name: 'IdeaEdit',
        component: (): Promise<any> => import(/* webpackChunkName: "Submit" */ '../pages/ideas/submitted.vue'),
    },
    {
        path: '/ideas/closure-report/:id?',
        name: 'ClosureReport',
        beforeEnter: async (to, from, next) => {
            if (await Permissions.canViewClosureReportAsync(to.params.id)) {
                next();
            } else {
                // eslint-disable-next-line sonarjs/no-duplicate-string
                next('/page-not-found');
            }
        },
        component: (): Promise<any> => import(/* webpackChunkName: "Submit" */ '../pages/closure-report/closure-report.vue'),
    },
    {
        path: '/financial/:area?',
        name: 'Financial',
        component: (): Promise<any> => import(/* webpackChunkName: "Financial" */ '../pages/financial/financial.vue'),
        beforeEnter: async (to, from, next) => {
            if (await Permissions.canViewFinancialAsync()) {
                next();
            } else {
                next('/page-not-found');
            }
        },
    },
    {
        path: '/financial/use-cases/:id?',
        name: 'FinancialEvaluation',
        component: (): Promise<any> => import(/* webpackChunkName: "Financial" */ '../pages/financial-evaluation/financial-evaluation.vue'),
    },
    {
        path: '/page-not-found',
        name: 'PageNotFound',
        component: (): Promise<any> => import(/* webpackChunkName: "Financial" */ '../pages/page-not-found/index.vue'),
    },
];

const router = new VueRouter({
    routes,
});

export default router;
