import { ClosureReportAddRequest } from '@/service-proxies/service-proxies.g';
import { CostsAndBenefitsClosureReport } from './cost-and-benefits-closure-report';
import { IntangibleBenefitItem } from './intangible-benefit-item';
import { ServiceRecipientBusinessUnit } from './service-recipient-business-unit';

export class ClosureReportForm {
    ideaId?: number;
    coodeId?: string;
    serviceRecipientBusinessUnits?: ServiceRecipientBusinessUnit[];
    wbsElement?: string;
    productOwnerId?: string;
    businessOwnerId?: string;
    businessOwnerName?: string;
    productOwnerName?: string;
    achivementOfUseCase?: number;
    restrictions?: string;
    costAndBenefits?: CostsAndBenefitsClosureReport;
    intangibleBenefits?: IntangibleBenefitItem[];
    recommendations?: string;
    lessonsLearned?: string;
    statusId?: number;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    isClosureReportEditable?: boolean;
    hasClosureReport?: boolean;
    ideaName?: string;

    public constructor(data?: ClosureReportAddRequest | any) {
        this.ideaId = data?.ideaId;
        this.coodeId = data?.coodeId;
        this.productOwnerId = data?.productOwnerId;
        this.productOwnerName = data?.productOwnerName;
        this.businessOwnerId = data?.businessOwnerId;
        this.businessOwnerName = data?.businessOwnerName;
        this.serviceRecipientBusinessUnits = data?.serviceRecipientBusinessUnits;
        this.wbsElement = data?.wbsElement;
        this.achivementOfUseCase = data?.achivementOfUseCase;
        this.restrictions = data?.restrictions;
        this.costAndBenefits = new CostsAndBenefitsClosureReport(data?.costAndBenefits);
        this.intangibleBenefits = data?.intangibleBenefits;
        this.recommendations = data?.recommendations;
        this.lessonsLearned = data?.lessonsLearned;
        this.statusId = data?.statusId;
        this.endDate = data?.endDate;
        this.startDate = data?.startDate;
        this.isClosureReportEditable = data?.isClosureReportEditable;
        this.hasClosureReport = data?.hasClosureReport;
        this.ideaName = data?.ideaName;
    }
}
