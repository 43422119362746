import { DeliveryTeam } from '@/models';
import { ApiResponseList } from '@/models/api-response';
import Vue from 'vue';

export class DeliveryTeamService {

    public async getDeliveryTeams(): Promise<ApiResponseList<DeliveryTeam>> {
        return (await Vue.prototype.$sdk.$http?.$core.metaData.request
            .api.get(`delivery-teams`)).data?.result;
    }
}
