import { ClosureReportBenefitsDto } from '@/service-proxies/service-proxies.g';
import { CostsAndBenefitsItem } from './costs-and-benefits-item';

export class ClosureReportBenefit {
    realizedBenefits?: CostsAndBenefitsItem;
    scaledBenefits?: CostsAndBenefitsItem;
    totalRealizedBenefits?: number;
    totalScaledBenefits?: number;

    public constructor(data?: ClosureReportBenefitsDto) {
        this.realizedBenefits = new CostsAndBenefitsItem(data?.realizedBenefits);
        this.scaledBenefits = new CostsAndBenefitsItem(data?.scaledBenefits);
        this.totalRealizedBenefits = data?.totalRealizedBenefits;
        this.totalScaledBenefits = data?.totalScaledBenefits;
    }
}
